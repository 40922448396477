import {
  Text,
  Box,
  Heading,
  createIcon,
  Image,
  Flex,
  SimpleGrid
} from "@chakra-ui/react";
import { VFC } from "react";

import { getIntegrations } from "api/get-integrations";
import { Head } from "components/head";
import { Main } from "components/layout/main";
import { PageProps } from "pages/_app";
import { IconComponent } from "ui/icons";
import { Link } from "ui/link";
import { Video } from "ui/video";

type Blog = {
  title: string;
  description: string;
  url: string;
};

const DESTINATIONS = ["salesforce", "google-sheets", "hubspot", "netsuite"];

const DESTINATION_COLORS = {
  salesforce: "rgba(0,161,224,.1)",
  hubspot: "rgba(255,122,89,.1)",
  "google-sheets": "rgba(15,157,88,.1)",
  netsuite: "rgba(186,204,219,.2)",
};

export async function getStaticProps() {
  const integrations = await getIntegrations();

  const selectedBlogs = [
    {
      title: "What is Reverse ETL: The Definitive Guide",
      description: "Everything you need to know about Reverse ETL.",
      url: "https://hightouch.com/blog/reverse-etl",
    },
    {
      title: "What is a Composable CDP?",
      description: "Learn why Composable CDPs are seeing such rapid adoption, how they work, and why they're replacing traditional CDPs.",
      url: "https://hightouch.com/blog/composable-cdp",
    }
  ]

  return {
    props: {
      ...integrations,
      blogs: selectedBlogs,
    },
    revalidate: 60,
  };
}

const Overview: VFC<Readonly<PageProps & { blogs: Blog[] }>> = ({
  destinations,
  blogs,
}) => {
  const featuredDestinations = destinations.filter(({ slug }) =>
    DESTINATIONS.includes(slug)
  );

  return (
    <>
      <Head description="Get started with Hightouch" />
      <Main>
        <Heading as="h1" textStyle="h1" mb={10}>
          Get started with Hightouch
        </Heading>
        <SimpleGrid columns={[1, 1, 1, 2]} gap={10}>
          <Card
            href="/getting-started/create-your-first-sync"
            icon={PlayIcon}
            title="Getting started"
            description="Work through a sample implementation and learn about the features available in Hightouch."
          />
          <Card
            href="/getting-started/concepts"
            icon={InfoIcon}
            title="Learn the concepts"
            description={`What's a model? What's a sync? We have an overview that teaches you the high-level working concepts in Hightouch.`}
          />
        </SimpleGrid>
        <Video
          source="https://cdn.sanity.io/files/pwmfmi47/production/bf1c3a72a5eae439a0475d76455b82e6e54badaf.mp4"
          poster="https://cdn.sanity.io/images/pwmfmi47/production/4155a32af105e32943817fefbe7fc01f771af613-1920x1080.webp"
          title="Learn how you can activate your data with Hightouch"
          description="Learn how you can activate your data with Hightouch"
          duration="5 min"
          uploadDate="2022-1-31T08:00:00+08:00"
        />
        <Heading as="h2" textStyle="h3" mb={10}>
          Popular destinations
        </Heading>
        <SimpleGrid columns={[1, 1, 1, 2]} gap={10}>
          {featuredDestinations.map(
            ({ slug, name, shortPitch, vendorAbout, icon }) => (
              <DestinationCard
                href={`/destinations/${slug}`}
                key={name}
                color={DESTINATION_COLORS[slug]}
                icon={icon}
                title={name}
                description={shortPitch || vendorAbout}
              />
            )
          )}
        </SimpleGrid>

        <Heading as="h2" textStyle="h3" mb={10}>
          Dive deeper
        </Heading>
        <Flex direction="column" gap={10} align="start">
          {blogs.map(({ title, description, url }) => (
            <BlogCard
              key={url}
              title={title}
              description={description}
              href={url}
            />
          ))}
        </Flex>
      </Main>
    </>
  );
};

const Card: VFC<
  Readonly<{
    icon: IconComponent;
    title: string;
    description: string;
    href: string;
  }>
> = ({ icon: Icon, title, description, href }) => {
  return (
    <Link
      maxWidth="420px"
      display="flex"
      href={href}
      _hover={{
        ".title": {
          color: 'grass',
        },
      }}
    >
      <Icon boxSize={10} />

      <Box ml={4}>
        <Text
          className="title"
          mb={2}
          fontWeight="semibold"
          fontSize="lg"
        >
          {title}
        </Text>
        <Text>{description}</Text>
      </Box>
    </Link>
  );
};

const DestinationCard: VFC<
  Readonly<{
    color: string;
    icon: string;
    title: string;
    description: string;
    href: string;
  }>
> = ({ title, description, icon, href }) => {
  return (
    <Link
      maxWidth="420px"
      display="flex"
      href={href}
      _hover={{ ".title": { color: 'grass' } }}
    >
      <Image src={icon} width="32px" height="32px" objectFit="contain" />

      <Box ml={4}>
        <Text
          className="title"
          mb={2}
          fontWeight="semibold"
          fontSize="lg"
        >
          {title}
        </Text>
        <Text>{description}</Text>
      </Box>
    </Link>
  );
};

const BlogCard: VFC<
  Readonly<{
    title: string;
    description: string;
    href: string;
  }>
> = ({ title, description, href }) => {
  return (
    <Link
      maxWidth="800px"
      href={href}
      display="flex"
      _hover={{
        ".title": {
          color: 'grass',
        },
      }}
    >

      <BlogIcon boxSize={10} />

      <Box ml={4}>
        <Text
          className="title"
          mb={2}
          fontWeight="semibold"
          fontSize="lg"
        >
          {title}
        </Text>
        <Text color="gray.600">{description}</Text>
      </Box>
    </Link>
  );
};

const PlayIcon = createIcon({
  displayName: "PlayIcon",
  viewBox: "0 0 37 37",
  path: [
    <path
      key={1}
      d="M33.5516 18.6254C33.5516 26.9382 26.8132 33.6766 18.5004 33.6766C10.1876 33.6766 3.44922 26.9382 3.44922 18.6254C3.44922 10.3141 10.1876 3.57422 18.5004 3.57422C26.8132 3.57422 33.5516 10.3141 33.5516 18.6254Z"
      fill="#4FC26B"
    />,
    <path
      key={2}
      d="M24.5202 18.6333C24.5202 19.0137 24.4005 19.3955 24.161 19.7013C24.1161 19.7613 23.9066 20.0085 23.742 20.1695L23.6522 20.2573C22.3951 21.5901 19.2673 23.5945 17.6809 24.2368C17.6809 24.2514 16.7381 24.6333 16.2891 24.6464H16.2292C15.5408 24.6464 14.8973 24.2675 14.568 23.653C14.3884 23.315 14.2238 22.3348 14.2089 22.3216C14.0742 21.4423 13.9844 20.0963 13.9844 18.6186C13.9844 17.0693 14.0742 15.6633 14.2388 14.8001C14.2388 14.7854 14.4034 13.9954 14.5082 13.732C14.6728 13.3531 14.9721 13.0298 15.3463 12.8249C15.6456 12.6801 15.9598 12.6055 16.2891 12.6055C16.6333 12.6216 17.2768 12.8396 17.5312 12.942C19.2074 13.5857 22.41 15.6925 23.6372 16.98C23.8468 17.1848 24.0712 17.435 24.1311 17.4921C24.3855 17.814 24.5202 18.209 24.5202 18.6333Z"
      fill="white"
    />,
  ],
});

const InfoIcon = createIcon({
  displayName: "InfoIcon",
  viewBox: "0 0 36 31",
  path: [
    <path
      key={1}
      d="M18.0004 1.5625C10.3412 1.5625 4.125 7.82383 4.125 15.5356C4.125 23.2501 10.3412 29.5086 18.0004 29.5086C25.6735 29.5086 31.8758 23.2501 31.8758 15.5356C31.8758 7.82383 25.6735 1.5625 18.0004 1.5625Z"
      fill="#4FC26B"
    />,
    <path
      key={2}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9997 22.0762C18.6657 22.0762 19.2207 21.5159 19.2207 20.8465L19.2207 14.6704C19.2207 13.9983 18.6657 13.4548 17.9997 13.4548C17.3336 13.4548 16.7925 13.9983 16.7925 14.6704L16.7925 20.8465C16.7925 21.5159 17.3336 22.0762 17.9997 22.0762ZM19.2071 10.24C19.2071 9.56931 18.666 9.01039 17.9861 9.01039C17.32 9.01039 16.7789 9.56931 16.7789 10.24C16.7789 10.9107 17.32 11.4557 17.9999 11.4557C18.666 11.4557 19.2071 10.9107 19.2071 10.24Z"
      fill="white"
    />,
  ],
});

const BlogIcon = createIcon({
  displayName: "BlogIcon",
  viewBox: "0 0 32 33",
  path: [
    <path
      key={1}
      d="M21.3296 4.41797H10.6715C6.80559 4.41797 4.55469 6.57489 4.55469 10.2707V22.7882C4.55469 26.5446 6.80559 28.6531 10.6715 28.6531H21.3296C25.2579 28.6531 27.4452 26.5446 27.4452 22.7882V10.2707C27.4452 6.57489 25.2579 4.41797 21.3296 4.41797Z"
      fill="#4FC2F4"
    />,
    <path
      key={2}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0145 10.0527V10.0649C10.4664 10.0649 10.0226 10.489 10.0226 11.01C10.0226 11.5311 10.4664 11.9552 11.0145 11.9552H14.8156C15.3637 11.9552 15.8088 11.5311 15.8088 10.9967C15.8088 10.4768 15.3637 10.0527 14.8156 10.0527H11.0145ZM20.9846 17.4325H11.0145C10.4664 17.4325 10.0226 17.0084 10.0226 16.4874C10.0226 15.9663 10.4664 15.541 11.0145 15.541H20.9846C21.5314 15.541 21.9765 15.9663 21.9765 16.4874C21.9765 17.0084 21.5314 17.4325 20.9846 17.4325ZM20.9847 22.9702H11.0146C10.6331 23.0186 10.2643 22.8369 10.0608 22.5339C9.85734 22.2189 9.85734 21.819 10.0608 21.5161C10.2643 21.201 10.6331 21.0314 11.0146 21.0677H20.9847C21.4921 21.1162 21.8749 21.5282 21.8749 22.025C21.8749 22.5085 21.4921 22.9217 20.9847 22.9702Z"
      fill="white"
    />,
  ],
});

export default Overview;
